import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import Map from "../components/Contact/MapContent";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Services/ServicesHome_3";
import HeroSection from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import BlockTwo from "../components/block/BlockTwo";
import BlockThree from "../components/block/BlockThree";
import BlockOne from "../components/block/BlockFour";
import ValuesOne from "../components/values/Values_2";
import VideoPromocional from "../components/Home/VideoPromocional";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <HeroSection urlVideo="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Video%2FConstruction.mp4?alt=media&token=5b3fa056-5cfe-44f2-ae56-7b1d48291079" />

        <BlockOne
          title={rpdata?.dbSlogan?.[2]?.slogan}
          text={rpdata?.dbHome?.[0].text}
          image1={rpdata?.stock?.[12]}
          image2={rpdata?.stock?.[8]}
          image3={rpdata?.stock?.[9]}
          sloganPrincipal={true}
          listServices={true}
        />
         {/* video promocional */}
         <VideoPromocional
            title={rpdata?.dbSlogan?.[2]?.slogan}
            text={rpdata?.dbAbout?.[1]?.text}
            linkVideo={`${rpdata?.videosPromo?.[0].link}`}
            image={`${rpdata?.videosPromo?.[0].img}`}
          />

        <ValuesOne
          image={`https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/stock%2F15%20YEARS-01.png?alt=media&token=0c132e92-b76e-420e-9184-61d2bc094619`}
        />
        <Directories />

       
        <CounterUp image={rpdata?.stock?.[5]} />
        <BlockTwo
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image={rpdata?.stock?.[6]}
        />
        <BlockThree
          title={rpdata?.dbSlogan?.[4]?.slogan}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.stock?.[10]}
          listsAbout={true}
        />

        {/* our reviews */}
        {rpdata?.reviews?.isHomeOnly === true ? (
          <div className="w-4/5 mx-auto py-[100px]">
            <h1 className="pb-10 text-center"> Our Reviews</h1>
            <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
          </div>
        ) : null}

        <ServicesHome />

        {/* Paleta de Colores */}

        {rpdata?.brandingExtra?.[0]?.activo ? <Paletacolor /> : null}

        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
